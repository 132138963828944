import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import NavigationScroll from 'layout/NavigationScroll';

import Routes from 'routes';
import themes from 'themes';

import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { excecuteRequestv2 } from 'api/requestv2';

const App = () => {

    const customization = useSelector((state) => state.customization);
    const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();

    useEffect(() => {

        
        const fetchUserId = async () => {
            if (isAuthenticated && user) {
                try {
                    const id = await excecuteRequestv2(`/get_user_id?auth_id=${user.sub}`)
                    
                    if (id.id) {

                        localStorage.setItem('loggedin', id.id)

                    } else {

                        console.error("No user found:", user.sub);

                    }
                } catch (error) {
                    console.error("Error fetching user:", error);
                }
            }
        };

        fetchUserId();

    }, [isAuthenticated, user]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        loginWithRedirect();
        return <div>Redirecting to login...</div>;
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
