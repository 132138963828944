import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useState } from 'react';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { excecuteRequestv2 } from 'api/requestv2';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //


const NavGroup = ({ item }) => {
    const theme = useTheme();
    const [role, setRole] = useState(false)
    const { user } = useAuth0();

    async function fetchUser(){
        const response = await excecuteRequestv2(`/users/${user.sub}`)
        setRole(response.role)
    }

    useEffect(()=> {
        fetchUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [])

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return role && ((role !== 'Admin' && menu.title !== 'User Management') || (role === 'Admin' && menu.title === 'User Management') || (role === 'Admin' && menu.title !== 'User Management')) && <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="body2" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>          
            <List
                subheader={
                    item.title && (
                        <Typography variant="body" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
