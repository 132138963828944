

const env = {
    url: 'https://api.planetary.blue/api/v2'
}

export async function excecuteRequestv2(endpoint, payload=false) {
    try {
        const isFormData = payload instanceof FormData;
        const response = await fetch(`${env.url}${endpoint}`, {
          method: payload ? 'POST' : 'GET',
          headers: isFormData
            ? undefined
            : {
                'Content-Type' : 'application/json',
              },
          body: payload? isFormData ? payload : JSON.stringify(payload) : undefined,
        });
  
        const data = await response.json();
        data.status = response.status
        return data;

      } catch (error) {
        return {"message" : "Something went wrong", "status" : 404}
      }
  }
  