import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';

import App from 'App';
import { store } from 'store';

import 'assets/scss/style.scss';
import config from './config';

// Your Auth0 domain and client id
const auth0Domain = "planetaryblue.us.auth0.com";
const auth0ClientId = "oeBTREQI1K2VPO0o9hzrjoS0Hz8VJSv9";

// Ensure `root` is defined before it's used
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    redirectUri={window.location.origin}
  >
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
          <App/>
      </BrowserRouter>
    </Provider>
  </Auth0Provider>
);


