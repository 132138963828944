import dashboard from './dashboard';
import resources from './resources';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [resources, dashboard]
};

export default menuItems;
